import React, { useEffect, useState } from "react";
import { Input, Radio, DatePicker } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";

function Question(props) {
  const [nextQuestionOption, setNextQuestionOption] = useState([])
  const history = useHistory();
  const navigateHome = () => {
    history.push("/home");
  };

  const onChange = (e) => {
    const selectedOptionIndex = nextQuestionOption
      .map((option) => option.c_value)
      .indexOf(e.target.value);
    const selectedOption = nextQuestionOption[selectedOptionIndex];
    props.saveAnswer(e.target.value, props.question.id);

    if (selectedOption.next_question_id < 0 || selectedOption.next_group < 0) {
      navigateHome();
    }
    else if (selectedOption.next_question_id > 0) {
      // check if need to switch group or jumping on some question within same group
      if (selectedOption.next_group > 0) {
        props.switchToGroupAndQuestion(
          selectedOption.next_group,
          selectedOption.next_question_id
        );
      } else props.switchToQuestion(selectedOption.next_question_id);
    } else if (selectedOption.next_group > 0) {
      props.switchToGroupAndQuestion(selectedOption.next_group, -1);
    }
  };

  useEffect(() => {
    // in case of edit application
    if(props.answer) props.saveAnswer(props.answer, props.question.id);
    ///
    axios
    .get("/api/question/nextquestion", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    })
    .then((response) => {
      if (response.data) {
        setNextQuestionOption(response.data);
      }
    });
  }, []);

  const getControl = (type) => {
    switch (type) {
      case "Radio":
        return (
          <Radio.Group required onChange={onChange} defaultValue={props.answer} defaultChecked="false">
            <Radio value={"1"}>Yes</Radio>
            <Radio value={"0"}>No</Radio>
          </Radio.Group>
        );
      case "DatePicker":
        return (
          <DatePicker required
            defaultValue={props.answer ? moment(props.answer,"YYYY-MM-DD"): moment(new Date(),"YYYY-MM-DD")}
            onChange={(date, dateString) =>
              props.saveAnswer(dateString, props.question.id)
            }
          />
        );
      default:
        return (
          <Input
            required
            defaultValue={props.answer}
            name={props.question.id}
            key={props.question.id}
            style={{ width: "15%" }}
            onChange={(answer) =>
              props.saveAnswer(answer.target.value, props.question.id)
            }
          ></Input>
        );
    }
  };
  return (
    <React.Fragment>
      <h2>{props.question.question}</h2>
      {getControl(props.question.input_type)}
    </React.Fragment>
  );
}
export default Question;
