import React, { useEffect, useState } from "react";
import axios from "axios";
import { Radio } from "antd";

const VisaQuestion = (props) => {
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [value, setValue] = useState(props.visaType.id);

  useEffect(() => {
    axios
      .get("/api/question/getApplicationTypes", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          withCredentials: true,
        },
        credentials: "include",
      })
      .then((response) => {
        setApplicationTypes(response.data);
      });
  }, []);

  const onChange = (e) => {
    setValue(e.target.value);
    var obj = {
      appId: e.target.value,
    };
    axios.post("/api/userApplications", obj, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },

      credentials: "include",
    });
    props.visaValue(obj);
  };

  return (
    <React.Fragment>
      <div style={{ alignItems: "center", textAlign: "center" }}>
        <h2>
          Hi, what type of visa application would you like to prepare today?
        </h2>
        <Radio.Group onChange={onChange} value={value} defaultChecked="false">
          {applicationTypes.map((applicationType) => {
            return (
              <Radio
                defaultValue={props.visaType.id}
                key={applicationType.id}
                value={applicationType.id}
                disabled={applicationType.disabled === 1 ? true : false}
              >
                {applicationType.type}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    </React.Fragment>
  );
};
export default VisaQuestion;
