import axios from "axios";

export const getUserFiles = () => {
  return axios
    .get("/api/user/files", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    })
}

export const rearrangeUserFiles = (files) => {
  return axios
    .post("/api/user/files", files, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    })
}

export const getUserApplicationAnswers = (appId) => {
  return axios
    .get("/api/userApplications/getAnswers/" + appId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    })

}

export const getUserApplicationLastAnswer = (appId) => {
  return axios
    .get("/api/userApplications/getLastAnswer/" + appId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    })

}

export const getNextQuestionOption = () => {
  return axios
    .get("/api/question/nextquestion", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    })

}


export const deleteUserApplication = (appId) => {
  return axios
    .delete("/api/userApplications/" + appId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    });

}

export const deleteUserFile = (fileId) => {
  return axios
    .delete("/api/user/files/" + fileId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
      },
      credentials: "include",
    });

}