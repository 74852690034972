import axios from "axios";

export const loadApplications = () => {
    return axios
      .get("/api/userApplications", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          withCredentials: true,
        },
        credentials: "include",
      });
      
}

export const loadApplicationQuestions = (appId) => {
  return axios
  .get("/api/question/getAssociatedQuestions/" + appId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
    },
    credentials: "include",
  });
}

export const saveApplicationAnswer = (answer) => {
  return axios
      .post("/api/question/Answer", answer, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          withCredentials: true,
        },

        credentials: "include",
      });
}

export const createUserVisaApplication = (visaValue) => {
  return axios.post("/api/userApplications", visaValue, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
    },

    credentials: "include",
  });
};