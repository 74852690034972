import React from "react";
import MyApplications from "./myApplications";
function Home() {
  return (
    <React.Fragment>
      <MyApplications />
    </React.Fragment>
  );
}
export default Home;
