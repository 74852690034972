import { Redirect, Route } from "react-router";

const PrivateRoute = ({component: Component, isAuthenticated, ...rest}) => {
    return (
      <Route {...rest}
        render={(props) => isAuthenticated() === true ? <Component {...props} /> : <Redirect to={{ 
          pathname: '/' ,
          state: {
            from: props.location
          }
        }} />}
      />
    )
  }

  export default PrivateRoute;