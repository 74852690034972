import React, { useEffect, useState } from "react";
import { Upload, message, List, Card, Image, Button, Popover } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./../App.css";
import { getUserFiles, deleteUserFile } from "../apis/userApis";
import pdfImage from "../images/pdf.svg";
import fileImage from "../images/file.svg";
import wordImage from "../images/word.svg";
import excelImage from "../images/excel.svg";
import picImage from "../images/image.svg";
import powerPointImage from "../images/powerpoint.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router";

function MyFiles() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const { Meta } = Card;
  const history=useHistory();

  const loadUserFiles = () => {
    getUserFiles().then((response) => {
      const filesList = [
        {
          title: "New File",
          appId: -1,
        },
      ];
      if(response.status === 401) {
        history.push("/");
      }
      else {
        if (response.data) {
          response.data.forEach((file) => {
            filesList.push(file);
          });
        }
        setFiles(filesList);
      }
    });
  };

  useEffect(() => {
    loadUserFiles();
  }, []);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    } else if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageUrl(imageUrl);
      });
      message.success(`${info.file.name} file uploaded successfully`);
      loadUserFiles();
    } else if (info.file.status === "error") {
      setFiles([]);
    }
  };

  const getIcon = (fileName) => {
    let extension = fileName.split(".").pop();
    switch (extension.toLowerCase()) {
      case "xlsx":
      case "xls":
        return excelImage;
      case "png":
      case "jpg":
      case "bmp":
      case "jpeg":
        return picImage;
      case "docx":
      case "doc":
        return wordImage;
      case "pdf":
        return pdfImage;
      case "pptx":
        return powerPointImage;
      default:
        return fileImage;
    }
  };

  const uploaderProps = {
    name: "file",
    action: "/api/user/file/upload",
    headers: {
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
    },
    credentials: "include",
    listType: "picture-card",
    className: "avatar-uploader",
    showUploadList: false,
    beforeUpload: beforeUpload,
    onChange: handleChange,
  };

  function beforeUpload(file) {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF file!");
    }
    return isPdf;
  }

  const content = (item) => (
    <div>
     <Button
        style={{ marginTop: "3%", border: "none" }}
        onClick={() => {
            deleteUserFile(item.id).then((resposne) => {
              loadUserFiles();
              message.success(`${item.name} file deleted successfully`);
            }
          );
        }}
      >
        Delete
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      <div className="main-card-style">
        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
          <List
            grid={{ gutter: 18, column: 6 }}
            dataSource={files}
            renderItem={(item, index) =>
              index === 0 ? (
                <List.Item>
                  <Upload {...uploaderProps}>{uploadButton}</Upload>
                </List.Item>
              ) : (
                <List.Item>
                  <Card hoverable>
                  <Popover
                      content={content(item)}
                      trigger="click"
                      placement="right"
                    >
                      <Button
                        style={{ padding: 0, float: "right", border: "none" }}
                      >
                        <MoreVertIcon />
                      </Button>
                    </Popover>
                    <Image
                      width={70}
                      src={getIcon(item.name)}
                      preview={false}
                    />
                    <Meta
                      style={{ fontSize: 18, paddingTop: 10 }}
                      description={item.name}
                    />
                  </Card>
                </List.Item>
              )
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default MyFiles;
