import React, { useState, useEffect } from "react";
import { message } from "antd";
import VisaQuestion from "./visaQuestionForm";
import AppSteps from "./appSteps";
import "./../../../node_modules/antd/dist/antd.css";
import "./index.css";
import { useHistory } from "react-router";
import { loadApplicationQuestions, saveApplicationAnswer } from "../../apis/applicationApis";
const _ = require('lodash'); 

function NewApplication() {
  const history = useHistory();
  const [associatedQuestions, setAssociatedQuestions] = useState({});
  const [visaValue, setVisaValue] = useState((history.location.state && history.location.state.visaType)? history.location.state.visaType:{});
  const [groupNames, setGroupNames] = useState([]);
  const [answers,setAnswers] = useState((history.location.state && history.location.state.answers)? history.location.state.answers: []);



    useEffect(() => {
      if(history.location.state){
        setVisaValue(history.location.state.visaType);
      }
    },[history.location.state]);



  const initiateApplication = async (questions) => {
        if (questions) {
          const groups = {};
          questions.forEach((question) => {
            var list = groups[question.group_name];
            if (list) list.push(question);
            else {
              list = [];
              list.push(question);
            }
            groups[question.group_name] = list;
          });
          setAssociatedQuestions(groups);
          setGroupNames(Object.keys(groups));
        }
    
  }

  useEffect(() => {    
    if(visaValue){
      loadApplicationQuestions(visaValue.appId).then((response) => {
        initiateApplication(response.data);
      });
    }
  }, [visaValue]);

  const setAnswersFunction = (answer) => {
    const index = answers.map(answer => answer.question_id).indexOf(answer.question_id);
    if(index >= 0){
      answers[index].answer = answer.answer;
      saveUserAnswer(answers[index]);
    }
    else {
      answers.push(answer);
      saveUserAnswer(answer);
    }
  };

  const saveUserAnswer = (answer) => {

    saveApplicationAnswer(answer).then((response) => {
      if (response.status === 200) {
        const index = answers.map(answer => answer.question_id).indexOf(answer.question_id);
        if(index >= 0){
          answers.splice(index,1,response.data[0]);
        }
        else {
          answers.push(response.data[0]);
        }
        setAnswers(_.cloneDeep(answers));
      }
    }).catch(error => { 
        message.error("Error occured while saving the answer.");
        history.push("/home");
    });;
   
  }

  return (
    <React.Fragment>
      <div
        className="main-card-style"
        style={{ marginLeft: "5%", marginRight: "5%", paddingTop: "10px" }}
      >
        <h1 style={{ textAlign: "center" }}>Visa Application Assistant</h1>
        {
            (groupNames.length > 0) ? (
            <AppSteps
              groups={groupNames}
              answers={answers}
              questions={associatedQuestions}
              //complete={() => createApplication()}
              saveAnswer={(answer) => setAnswersFunction(answer)}
            />
          ):( <VisaQuestion visaType={visaValue} visaValue={(value) => setVisaValue(value)} />)
        }
        
      </div>
    </React.Fragment>
  );
}

export default NewApplication;
