export const authenticate = (user) =>{
    sessionStorage.setItem("isAuthenticated",true);
    sessionStorage.setItem("user",user.id);
}

export const logout = () => {
    sessionStorage.setItem("isAuthenticated",false);
    sessionStorage.setItem("user",-1);
}

export const isAuthenticated = () => {
    const isAuthenticated = sessionStorage.getItem("isAuthenticated");
    return  (isAuthenticated !== undefined && isAuthenticated === 'true') ? true : false;
}