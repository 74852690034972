import React, { useEffect, useState } from "react";
import { Button, Steps, message } from "antd";
import Question from "./question";
import { useHistory } from "react-router";

const { Step } = Steps;

function AppSteps(props) {
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [current, setCurrent] = useState(0);
  const [answer, setAnswer] = useState({});
  const history = useHistory();

  const getQuestionAnswer = (question) => {
    const questionAnswers = props.answers.filter(answer => answer.question_id === question.id);    
    if(questionAnswers.length > 0) {
      return questionAnswers[0].answer;
    }
    else
      return null;
  }

  const getQuestion = (index) => {
    return (
      <Question
        switchToGroupAndQuestion={(groupId, questionId) =>
          switchToGroupAndQuestion(groupId, questionId)
        }
        switchToQuestion={(questionId) => switchToQuestion(questionId)}
        answer={getQuestionAnswer(props.questions[currentGroup][index])}
        question={props.questions[currentGroup][index]}
        saveAnswer={(answer, questionId) => handleInput(answer, questionId)}
      />
    );
  };

  let currentGroup = props.groups[currentGroupIndex];
  const stepTitles = props.groups.map((group) => ({
    title: group,
    content: getQuestion(0),
  }));

  const [steps, setSteps] = useState(stepTitles);

  useEffect(() => {

    if(props.answers){
      const lastAnswer = props.answers[props.answers.length-1];
      if(lastAnswer && lastAnswer.question_id){ 
        
        const groupIndex = getGroupIndex(lastAnswer.group_id);
        const questionIndex = getQuestionIndex(groupIndex,lastAnswer.question_id);
        currentGroup = props.groups[groupIndex];
        const totalQuestions = props.questions[currentGroup].length;
        if (questionIndex + 1 >= totalQuestions) {
          if (groupIndex + 1 >= props.groups.length) return;
          currentGroup = props.groups[groupIndex + 1];
          steps[groupIndex + 1].content = getQuestion(0);
          setCurrentGroupIndex(groupIndex + 1);
          setCurrent(0);
        } else {
          steps[groupIndex].content = getQuestion(questionIndex + 1);
          setCurrentGroupIndex(groupIndex);
          setCurrent(questionIndex + 1);
        }
        setAnswer({
          question_id: lastAnswer.question_id,
          answer: lastAnswer.answer,
          id: lastAnswer.id
        });
      }
    }
  },[]);

  const next = async () => {
    props.saveAnswer(answer);
    const totalQuestions = props.questions[currentGroup].length;
    if (current + 1 >= totalQuestions) {
      if (currentGroupIndex + 1 >= props.groups.length) return;
      currentGroup = props.groups[currentGroupIndex + 1];
      steps[currentGroupIndex + 1].content = getQuestion(0);
      setCurrent(0);
      setCurrentGroupIndex(currentGroupIndex + 1);
    } else {
      steps[currentGroupIndex].content = getQuestion(current + 1);
      setCurrent(current + 1);
    }
  };

  const getGroupIndex = (groupId) => {
    const groupMap = {};
    const groupNames = Object.keys(props.questions);
    const questionGroups = props.questions;
    groupNames.forEach((groupName) => {
      const questions = questionGroups[groupName];
      if (questions.length > 0) groupMap[questions[0].group_id] = groupName;
    });
    const groupIndex = props.groups.indexOf(groupMap[groupId]);
    return groupIndex;
  };

  const getQuestionIndex = (groupIndex, questionId) => {
    const groupName = props.groups[groupIndex];
    return props.questions[groupName]
      .map((question) => question.id)
      .indexOf(questionId);
  };

  const switchToGroupAndQuestion = async (groupId, questionId) => {
    const groupIndex = getGroupIndex(groupId);
    currentGroup = props.groups[groupIndex];
    let questionIndex = 0;
    if (questionId > 0)
      questionIndex = getQuestionIndex(groupIndex, questionId);
    setCurrent(questionIndex);
    steps[groupIndex].content = getQuestion(questionIndex);
    setCurrentGroupIndex(groupIndex);
  };

  const switchToQuestion = (questionId) => {
    if (questionId >= 0) {
      const questionIndex = getQuestionIndex(currentGroupIndex, questionId);
      setCurrent(questionIndex);
      steps[currentGroupIndex].content = getQuestion(questionIndex);
    } else {
      steps[currentGroupIndex].content = getQuestion(0);
      setCurrent(0); // move to next
    }
  };
  const prev = async () => {
    if (current - 1 < 0) {
      let groupIndex = currentGroupIndex;
      if(currentGroupIndex - 1 >= 0) 
         groupIndex = groupIndex - 1 ;
      currentGroup = props.groups[groupIndex];
      let questionIndex = props.questions[currentGroup].length;
      if(questionIndex > 0) questionIndex = questionIndex - 1;
      setCurrent(questionIndex);
      steps[groupIndex].content = getQuestion(questionIndex);
      setCurrentGroupIndex(groupIndex);
    }
    else{
      steps[currentGroupIndex].content = getQuestion(current - 1);
      setCurrent(current - 1);
    }
  };

  const handleInput = (answer, questionId) => {
    const questionAnswers = props.answers.filter(answer => answer.question_id === questionId);    
    if(questionAnswers.length > 0){
      questionAnswers[0].answer = answer;
      setAnswer(questionAnswers[0]);
    }
    else {
      setAnswer({
        question_id: questionId,
        answer: answer
      });
    }
  };

  const saveInDB = async () => {
    await props.saveAnswer(answer);
    history.push("/home");
    message.success(
      "Congratulations! You visa application has been completed successfully."
    );
  };
  return (
    <div style={{ alignItems: "center", textAlign: "center" }}>
      <Steps current={currentGroupIndex} direction="horizontal">
        {steps.map((item) => (
          <Step
            key={item.title}
            title={item.title}
            style={{ paddingTop: "10px" }}
          />
        ))}
      </Steps>
      <div style={{ paddingTop: "5px" }}>
        {steps[currentGroupIndex].content}
      </div>
      <div className="steps-action">
        {(current < props.questions[currentGroup].length - 1 ||
          currentGroupIndex < props.groups.length - 1 ||
          (currentGroupIndex < props.groups.length - 1 &&
            props.questions[currentGroup].length === 1)) && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === props.questions[currentGroup].length - 1 &&
          currentGroupIndex === props.groups.length - 1 && (
            <Button type="primary" onClick={() => saveInDB()}>
              Done
            </Button>
          )}
        {(current > 0 || (current === 0 && currentGroupIndex > 0)) && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </div>
  );
}

export default AppSteps;
