import React, { useEffect, useState } from "react";
import { Button, List, Card, Image, Popover } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PDFDocument } from "pdf-lib";
import { useHistory } from "react-router-dom";
import "./../App.css";
import {
  getUserApplicationAnswers,
  getUserFiles,
  deleteUserApplication
} from "../apis/userApis";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import appImage from "../images/application.png";
import { loadApplications } from "../apis/applicationApis";
function MyApplications() {
  const [applications, setApplication] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [PDFName, setPDFName] = useState([]);
  const { Meta } = Card;

  const history = useHistory();
  const newApplication = (visaValue,appAnswers) => {
    if(appAnswers) {
      history.push({
        pathname: "newApplication",
        state: { 
          visaType: visaValue,
          answers : appAnswers
        }
      });        
    }
    else if(visaValue){
      history.push({
        pathname: "newApplication",
        state: { 
          visaType: visaValue
        }
      }); 
    }
    else history.push("newApplication");
  };

  const editApplication = async (item) => {

    const userAnswers = await getUserApplicationAnswers(item.appId);
    if(userAnswers.data !== ""){ // application answers exist
      newApplication(item, userAnswers.data);
      setAnswers(userAnswers.data);
    }
    else newApplication(item);
  };

  const loadApplicationList = () => {
    loadApplications().then((response) => {
      var applicationList = [];
      if (response.data) {
        applicationList = response.data.map((application) => {
          return {
            title: application.type,
            appId: application.application_id,
            pdfName: application.pdf_name,
          };
        });
      }
      // add dumy place holder application in list
      applicationList.splice(0, 0, {
        title: "New Application",
        appId: -1,
      });
      setApplication(applicationList);
    });
  };

  useEffect(() => {
    history.push("/home");
    loadApplicationList();
  }, []);

  // const getMimeType = (fileName) => {
  //   let extension = fileName.split(".").pop();
  //   switch (extension.toLowerCase()) {
  //     case "txt":
  //       return "text/plain";
  //     case "xlsx":
  //       return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //     case "xls":
  //       return "application/vnd.ms-excel";
  //     case "png":
  //       return "image/png";
  //     case "jpg":
  //       return "image/jpg";
  //     case "bmp":
  //       return "image/bmp";
  //     case "jpeg":
  //       return "image/jpg";
  //     case "docx":
  //       return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  //     case "doc":
  //       return "application/msword";
  //     case "pdf":
  //       return "application/pdf";
  //     default:
  //       return "application/octet-stream";
  //   }
  // };

  // const attachUserFile = async (pdfDoc, fileName) => {
  //   const url = "/api/user/file/" + fileName;
  //   const fileAttachmentBytes = await fetch(url).then((res) =>
  //     res.arrayBuffer()
  //   );
  //   await pdfDoc.attach(fileAttachmentBytes, fileName, {
  //     mimeType: getMimeType(fileName),
  //     description: fileName,
  //   });
  // };

  const getPDFPages = async (pdfDoc, fileName) => {
    const url = "/api/user/file/" + fileName;
    const pdfFileBytes = await fetch(url).then((res) => res.arrayBuffer());

    const userPdfFile = await PDFDocument.load(pdfFileBytes,{ignoreEncryption: true});
    const userPdfPages = await pdfDoc.copyPages(
      userPdfFile,
      userPdfFile.getPageIndices()
    );
    return userPdfPages;
  };

  async function fillVisaForm(userFiles) {
    const pdfDoc = await PDFDocument.create();
    // userFiles.forEach((userFile) => {
    //   attachUserFile(pdfDoc, userFile.name);
    // });

    var path = "./resources/" + PDFName;
    const formPdfBytes = await fetch(path).then((res) => res.arrayBuffer());

    const formpdf = await PDFDocument.load(formPdfBytes,{ignoreEncryption: true});
    const form = formpdf.getForm();

    answers.forEach((answer) => {
      if (answer.pdf_field_name) {
        const field = form.getTextField(answer.pdf_field_name);
        if (field) field.setText(answer.answer);
      }
    });
    form.flatten();

    const formPdfPages = await pdfDoc.copyPages(
      formpdf,
      formpdf.getPageIndices()
    );
    formPdfPages.map((page) => pdfDoc.addPage(page));

    for (var i = 0; i < userFiles.length; i++) {
      const userPdfsPages = await getPDFPages(pdfDoc, userFiles[i].name);
      userPdfsPages.map((page) => pdfDoc.addPage(page));
    }

    const pdfBytes = await pdfDoc.save();
    saveByteArray("Visa Application.pdf", pdfBytes);
  }

  const saveByteArray = (pdfName, byte) => {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = pdfName;
    link.download = fileName;
    link.click();
  };

  const createPDF = async (item) => {
    const userAnswers = await getUserApplicationAnswers(item.appId);
    setPDFName(item.pdfName);
    setAnswers(userAnswers.data);
  };

  useEffect(() => {
    if (answers.length > 0) {
      getUserFiles().then((response) => {
        fillVisaForm(response.data);
      });
    }
  }, [answers]);

  const content = (item) => (
    <div>
      <Button style={{ border: "none" }} onClick={() => editApplication(item)}>
        Edit
      </Button>
      <br />
      <Button style={{ border: "none" }} onClick={() => createPDF(item)}>
        Download
      </Button>
      <br />
      <Button
        style={{ marginTop: "3%", border: "none" }}
        onClick={() => {
          deleteUserApplication(item.appId).then((resposne) =>
            loadApplicationList()
          );
        }}
      >
        Delete
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      <div>
        <div className="main-card-style">
          <List
            grid={{ gutter: 18, column: 6 }}
            dataSource={applications}
            renderItem={(item, index) =>
              index === 0 ? (
                <List.Item>
                  <Button
                    onClick={(e) => newApplication(e.target.value)}
                    className="add-button"
                    type="dashed"
                  >
                    <PlusOutlined /> <br /> New Application
                  </Button>
                </List.Item>
              ) : (
                <List.Item>
                  <Card hoverable>
                    <Popover
                      content={content(item)}
                      trigger="click"
                      placement="right"
                    >
                      <Button
                        style={{ padding: 0, float: "right", border: "none" }}
                      >
                        <MoreVertIcon />
                      </Button>
                    </Popover>
                    <Image width={70} src={appImage} preview={false} />
                    <Meta
                      style={{ paddingLeft: 0, paddingTop: 10 }}
                      description={item.title}
                    />
                  </Card>
                </List.Item>
              )
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default MyApplications;
