import "./../node_modules/antd/dist/antd.css";
import "./index.css";
import "./App.css";
import axios from "axios";
import { Menu, Button, Row, Col, Dropdown } from "antd";
import { useHistory, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import Home from "./forms/home";
import { UserOutlined } from "@ant-design/icons";
import Login from "./forms/login/login";
import MyFiles from "./forms/myFiles";
import NewApplication from "./forms/newApplication/newApplication";
import PrivateRoute from "./PrivateRoute";
import { isAuthenticated, logout } from "./auth";
import React, { useState } from "react";
import companylogo from "./resources/company logo.png";

function App(props) {
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState((history.location.pathname === "/myFiles" ? "myfiles" : "applications"));
  const navigateHome = () => {
    setSelectedKey("applications");
    history.push("/home");
  };

  const navigateMyFiles = () => {
    setSelectedKey("myfiles");
    history.push("/myFiles");
  };

  const authenticationResponse = (success) => {
    if (success) selectedView();
  };

  const funLogout = () => {
    axios.delete("/api/user/logout").then((response) => {
      logout();
      history.push("/");
    });
  };

  const selectedView = () =>{
    if(selectedKey === "applications") history.push("/home");
    else history.push("/myFiles");
  }

  const menu = (
    <Menu
      hidden={!isAuthenticated()}>
      <Menu.Item
        key="logout"
        onClick={funLogout}
        style={{
          border: "1px solid rgb(255 255 255)",
          width: "120px",
        }}
      >
        <div className="navbar-style-div">
          <div className="navbar-style-subdiv">Logout</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <div>
        <Menu mode="horizontal"  hidden={!isAuthenticated()} style={{height:60}}>
          <Row style={{ alignItems: "center"}} >
            <Col span={4}>
              <div className="navbar-logo-title">
                <img
                  src={companylogo}
                  style={{ width: "12%" }}
                  alt="companylogo"
                />
                <div>Visa Application Assistant</div>
              </div>
            </Col>
            <Col span={10} style={{ paddingBottom: 16, border: 0}}>
            <Menu mode="horizontal" selectedKeys={selectedKey} hidden={!isAuthenticated()}>
                <Menu.Item
                  key="applications"
                  onClick={navigateHome}
                  style={{
                    border: "1px solid rgb(255 255 255)",
                    width: "120px",
                  }}
                >
                  <div className="navbar-style-div">
                    <div className="navbar-style-subdiv">Applications</div>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="myfiles"
                  onClick={navigateMyFiles}
                  style={{
                    border: "1px solid rgb(255 255 255)",
                    width: "120px",
                  }}
                >
                  <div className="navbar-style-div">
                    <div className="navbar-style-subdiv">Personal files</div>
                  </div>
                </Menu.Item>
                {/* <Menu.Item
                key="logout"
                onClick={funLogout}
                style={{
                  border: "1px solid rgb(255 255 255)",
                  width: "120px",
                }}
              >
                <div className="navbar-style-div">
                  <div className="navbar-style-subdiv">Logout</div>
                </div>
              </Menu.Item> */}
              </Menu>
            </Col>
            <Col span={8}></Col>
            <Col span={2}>
              <div style={{ display: "flex", placeContent: "space-evenly" }}>
                <Dropdown overlay={menu}>
                  <Button
                    shape="circle"
                    icon={<UserOutlined />}
                    style={{ border: "2px solid #039BE5" }}
                />
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Menu>
        <div>
          <Switch>
            <PrivateRoute
              exact
              path="/home"
              isAuthenticated={isAuthenticated}
              component={Home}
            />
            <PrivateRoute
              exact
              path="/newApplication"
              isAuthenticated={isAuthenticated}
              component={NewApplication}
            />
            <PrivateRoute
              exact
              path="/myFiles"
              isAuthenticated={isAuthenticated}
              component={MyFiles}
            />
            <Route
              {...props}
              path="/"
              component={() => (
                <Login authenticationResponse={authenticationResponse} />
              )}
            />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(App);
