import React, { useEffect } from "react";
import { Form, Input, Button, Modal, message, Card, Image } from "antd";
import { useState } from "react";
import { observer } from "mobx-react";
import logo from "./../../resources/company logo.png";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import RegistrationForm from "./signup";
import { useHistory } from "react-router";
import axios from "axios";
import { isAuthenticated, authenticate, logout } from "../../auth";

const LoginForm = (props) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) history.push("/home");
  }, []);

  const onFinish = (values) => {
    handleRegister(values);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  function handleRegister(e) {
    axios
      .post("/api/user/login", e, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then(
        (response) => {
          authenticate(response.data);
          props.authenticationResponse(true);
        },
        (error) => {
          logout();
          message.error("Invalid email or password");
        }
      );
  }

  return (
    <React.Fragment>
      <div
        style={{
          width: "35%",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "25px",
          border: "2px solid",
          margin:"5% 30%" ,
          borderColor: "lightgray",
          alignItems: "center",
          backgroundColor:"white",
          textAlign:"center"
        }}
      >
        <div style={{ marginLeft: "15%", marginTop: "4%", width: "35%" }}>
          <Form
            title="non"
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <h2>Login</h2>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {/* <Form.Item>
              <a className="login-form-forgot" href="">
                Forgot password
              </a>
            </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
              <span>  Or</span> <a onClick={showModal}>Register now!</a>
            </Form.Item>
            <Modal
              okText="Register"
              title="Register"
              visible={modalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <RegistrationForm />
            </Modal>
          </Form>
        </div>
        <div style={{ width: "50%" }}>
          <Card size="small" bordered={false}>
            <Image src={logo} preview={false} />
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};
export default observer(LoginForm);
